import React from "react"
import Layout from "../../gatsby-theme-blog/components/layout.js"
import SEO from "../../gatsby-theme-blog/components/seo"
import Footer from "./footer"

export default ({ ...props }) => (
    <Layout {...props} location={props.location} title='pedro.fyi'>
        <SEO title="Notes" favicon="notes" />
        {props.children}
    </Layout>
);
